@import '../style/_theme.scss';

.BadgeWrapper {
  position: relative;
  .badge {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    
    width: 14px;
    height: 14px;
    padding: 1px;
    border-radius: 50%;
    
    fill: var(--secondary-black-75);
  }
}