@import '../style/_theme.scss';

.Chart {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;

  .mapDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .timeline {
    position: absolute;
    top: 250px;
    right: 12px;
    width: 36px;
    bottom: 90px;
    background-color: rgba(0,0,0, 0.5);
    border-radius: 3px;
    transition: all .2s ease-in-out;

    opacity: 0;
    transform: translateY(-50px);

    pointer-events: none;

    &[set=true] {
      opacity: 1;
      transform: translateY(0px);
    }

    .today {
      position: absolute;
      top: 83px;
      left: -4px;
      right: -4px;
      height: 8px;
      border: 2px solid red;
      border-radius: 4px;
    }

    .days {
      .day {
        flex: 1 1 auto;

        &:nth-child(even) {
          background-color: rgba(0,0,0, 0.5);
        }

        .hourMark {
          flex: 1 1 auto;
          min-height: 5px;
          border-bottom: 1px solid rgba(0,0,0,0.3);
          font-size: 0.5rem;
          text-align: center;
          color: rgba(255,255,255, 0.8);
          padding: 0 4px;

          &:nth-last-child(1) {
            border: none;
          }
        }
      }
    }

    .dayNames {
      .dayName {
        flex: 1 1 auto;
        width: 10px;
        transform: rotate(90deg);
        font-size: 0.8rem;
        color: rgba(255,255,255, 0.8);
      }
    }
  }

  .customFabs {
    position: absolute;
    right: 0;
    top: 250px;
    z-index: var(--z-index-chart-fabs);
    transition: top .2s ease-in-out;
  }

  .recordingPill {
    position: absolute;
    right: 10px;
    top: 55px;
    z-index: var(--z-index-chart-fabs);
    transition: top .2s ease-in-out;
  }

  .anchorAlarmControl {
    position: absolute;
    right: 10px;
    top: 55px;
    z-index: var(--z-index-chart-fabs);
    transition: top .2s ease-in-out;
  }

  @media (min-width: $break-tablet) {
    .anchorAlarmAdjusting {
      display: none;
    }
  }
}


.mapboxgl-canvas {
  outline: none;
}
.mapboxgl-canvas-container {
  cursor: auto !important;
}


/* TODO(jliebrand): MOAR HACK ALERT - overriding the css with !important seems
   a bit crude - isn't there a better way to style the controls?? */

:global(.mapboxgl-ctrl-top-right) :global(.mapboxgl-ctrl) {
  // border-radius: 4x !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  overflow: visible !important;
  z-index: var(--z-index-chart-fabs);
}
:global(.mapboxgl-ctrl-top-right .mapboxgl-ctrl button) {
  border-radius: 50% !important;
  background-color: #fff;
  box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
  margin: 0;
}

:global(.mapboxgl-ctrl > button:hover) {
  background-color: #f2f2f2 !important;
}

:global(.mapboxgl-ctrl > button:focus) {
  -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%) !important;
  box-shadow: 0px 0px 0px 2px rgb(0 0 0 / 10%) !important;
}

:global(.mapboxgl-ctrl-bottom-left),
:global(.mapboxgl-ctrl-bottom-right) {
  transition: margin-bottom .2s ease-in-out;
  z-index: var(--z-index-chart-fabs);
}

:global(.mapboxgl-ctrl-top-right) {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 90px !important;
  transition: margin-top .2s ease-in-out;
  z-index: var(--z-index-chart-fabs);
}

:global(.offline-picker-scene .mapboxgl-ctrl) {
  display: none !important;
}
:global(.offline-picker-scene) .Chart > .customFabs {
  display: none;
}
:global(.offline-picker-scene) .Chart > .recordingPill {
  display: none;
}
:global(.offline-picker-scene) .Chart > .anchorAlarmControl {
  display: none;
}

:global(.mapboxgl-ctrl-attrib-button) {
  opacity: 0.3 !important;
  padding: 0 !important;
  margin: 0 !important;
}

:global(.mapboxgl-ctrl-attrib) {
  background-color: rgba(255, 255, 255, 0.5) !important;
  margin-left: 5.5px !important;
  margin-bottom: 4px !important;
}

:global(.mapboxgl-ctrl-scale) {
  background-color: transparent !important;
  pointer-events: none;
}


@media (max-width: $break-desktop) {
  :global(.mapboxgl-ctrl-group > button) {
    width: 40px !important;
    height: 40px !important;
  }

  .Chart {
    .customFabs {
      top: 295px;
    }
    .recordingPill {
      top: 45px;
    }
    .anchorAlarmControl {
      top: 45px;
    }
  }
}

@media (max-width: $break-tablet) {
  :global(.mapboxgl-ctrl-top-right) {
    margin-top: 50px !important;
  }
  
  .Chart {
    .customFabs {
      top: 115px;
    }
    .recordingPill {
      top: 70px;
      right: 50%;
      transform: translateX(50%)
    }
    .anchorAlarmControl {
      top: 70px;
      right: 50%;
      transform: translateX(50%)
    }
  }

  :global(.mapboxgl-ctrl-bottom-right),
  :global(.mapboxgl-ctrl-bottom-left) {
    margin-bottom: calc(var(--navbar-height, 0px) + var(--bs-pos, 0px));
  }


  // Hide the scale control if we are showing CTA buttons
  :global(.cta .mapboxgl-ctrl-bottom-right) {
    display: none;
  }

  /* scene specific positioning */
  :global(.route-scene .mapboxgl-ctrl-top-right),
  :global(.preview-scene .mapboxgl-ctrl-top-right) {
    margin-top: 85px !important;
  }
  :global(.route-scene) .Chart,
  :global(.preview-scene) .Chart {
    .customFabs {
      top: 150px;
    }
    .recordingPill {
      top: 105px;
    }
    .anchorAlarmControl {
      top: 105px;
    }
  }
}