@import '../../style/_theme.scss';
@import '../../style/_text-styles.scss';


.LayerFab {
  .button {
    padding: 8px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
    &:hover {
      background-color: #f2f2f2;
    }

    path {
      fill: var(--primary-blue-100);
    }
  }

  .layerPicker {
    position: relative;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-out;

    &[active] {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .backdrop {
    position: fixed;
    z-index: var(--below);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: default;
  }

  .layerContainer {
    z-index: var(--above);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 150px;

    position: absolute;
    z-index: var(--z-index-chart-fabs);
    left: -180px;
    top: 0;

    padding: 8px;
    background: var(--panel);
    border-radius: 4px;
    box-shadow:
      0px 5px 5px rgba(0, 0, 0, 0.2),
      0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);

    .layerButton {
      -webkit-tap-highlight-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 4px;
      cursor: pointer;
      width: 64px;
      line-height: 1.2;
    }

    .layerIcon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      svg {
        width: 40px;
        height: 40px;
      }
      &[locked] {
        svg {
          opacity: 0.2;
        }
      }
    }

    .layerTitle {
      padding-top: 8px;
      @include text-small-semibold;
      color: var(--secondary-black-50);
    }

    .selectedLayerButton {
      .layerIcon {
        border: 1px solid var(--secondary-blue-100);
      }
      .layerTitle {
        color: var(--secondary-blue-100);
      }
    }

  }
}