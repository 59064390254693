@import './_fonts.scss';
@import './_theme.scss';
@import './_colors.scss';
@import './_ordering.scss';
@import './_flexbox.scss';
@import './_normalize.scss';
@import './_skeleton.scss';
@import './_flexbox.scss';
@import './_transitions.scss';
@import './_text-styles.scss';

.savvy-green {
  color: $savvy-green;
}


/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* NOTE
html is set to 10px so that all the REM measurements throughout Skeleton
are based on 10px sizing. So basically 1.5rem = 15px :) */
html {
  // -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: 10px;
  user-select: none; }
body {
//  font-family: 'Roboto', sans-serif;
//  font-size: 1.6em; /* currently ems cause chrome bug misinterpreting rems on body element */
//  line-height: 1.6;
//  font-weight: $savvy-weight-normal;
  @include text-normal;

  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  color: $savvy-dark-text; }

p {
  margin-top: 0; }


input {
  width: 10rem;
  height: 1.8em;
  text-overflow: ellipsis;
  font-size: $savvy-font-normal;
  border-radius: 0;
  outline: none;
}

a {
  text-decoration: none;
  color: var(--primary-teal-100)
}


.notification-dot {
  position: relative;
}
.notification-dot:after {
  content: " ";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: var(--secondary-red-100);
}
// give the dot one unit (8px) margin
.dot-margin-1:after {
  margin: 8px;
}

.spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

:global {
  @keyframes shake {
    0% {
      transform: translate(3px, 0);
    }
    50% {
      transform: translate(-3px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}
