@import '../style/_theme.scss';
@import '../style/_elevation.scss';

.RecordingPill {
  @include shadow-1dp;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);

  opacity: 0.6;
  transition: opacity 0.2s ease-out;

  &[opaque] {
    opacity: 1;
  }

  .timeElapsed {
    min-width: 36px;
    text-align: right;
    margin: 0 4px;
    color: white;
  }

  .recordIcon {
    width: 16px;
    height: 16px;
    svg {
      width: 10px;
      height: 10px;
      fill: var(--secondary-red-100);
    }
  }

  .recordButton {
    width: 24px;
    height: 24px;
    svg {
      width: 14px;
      height: 14px;
      fill: white;
    }
  }

  
  @media (min-width: $break-desktop) {
    height: 30px;
    border-radius: 15px;

    .recordButton {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}