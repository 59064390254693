@import '../style/_theme.scss';
@import '../style/_text-styles.scss';

.Button {
  @include text-normal-semibold;

  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  margin-bottom: 0px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--secondary-blue-100);
  text-shadow: none;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-blue-100);
  border-image: initial;
  background: rgb(255, 255, 255);
  margin: 4px;
  transition: all 0.1s;
  outline: none;
  transition: opacity .2s ease-in-out;

  &.caption {
    @include text-caption-small-semibold;
  }

  &.teal {
    color: var(--primary-teal-100);
    border-color: var(--primary-teal-100);

    .icon svg {
      fill: var(--primary-teal-100);
    }
  }

  &.primary {
    background: var(--secondary-blue-100);
    color: white;
    .icon svg {
      fill: white;
    }

    &.teal {
      background: var(--primary-teal-100);
    }
  }
  
  &.record {
    background: var(--secondary-red-100);
    color: white;
  }

  &.rounded {
    border-radius: 24px;
    padding: 6px 16px;
    height: 40px;
  }

  &.fab {
    border-radius: 3rem;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14);
    border: none;
    height: 40px;
  }


  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  &.outline {
    border: none;
    background: 0;
  }

  &.link {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .icon {
    margin-right: 4px;
  }
}

.Button:active {
  opacity: 0.2;
}

.Button[disabled] {
  opacity: 0.4;
}


