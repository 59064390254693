.AppUpdateBanner {
  position: fixed;
  height: 100%;
  z-index: 10000;
  text-align: center;
  justify-content: center;
  left: 0;
  right: 0;
  background-color: #00000088;

  .box {
    background-color: #444;
    color: white;
    border-radius: 4px;
    width: 319px;
    height: min-content;
    margin-top: 16px;
    padding: 12px;
  }
}