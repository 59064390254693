@import '../../style/_ordering.scss';
@import '../../style/_colors.scss';
@import '../../style/_text-styles.scss';
@import '../../style/_elevation.scss';
@import '../../style/_theme.scss';

.SharedLandingScene {
  background-color: white;
  z-index: var(--z-index-scene);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  @media (min-width: $break-landscape-tablet) {
    text-align: left;
  }

  .container {
    max-width: 1200px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;

    @media (min-width: $break-tablet) {
      padding: 16px 64px;
    }
  }

  .header {
    padding: 16px 0;
    align-items: center;
    display: flex;
    flex-direction: column;

    .logo {
      width: 220px;
      @media (min-width: $break-landscape-tablet) {
        width: 400px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    @media (min-width: $break-landscape-tablet) {
      flex-direction: row;
    }

    .image {
      @include shadow-4dp;
      margin-bottom: 16px;
      width: 100%;

      img {
        width: 100%;
      }
    }

    // Keep image div aspect ratio to 1 / 2
    .imageLoading {
      padding-bottom: 50%;
      img {
        display: none;
        opacity: 0;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      padding: 16px;

      @media (min-width: $break-landscape-tablet) {
        padding-left: 32px;
      }
    }

    .title {
      @include text-extra-large-semibold;
    }

    .subtitle {
      @include text-large;
      color: var(--secondary-black-50);
      margin-top: 8px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .cta {
    margin: 16px 0;
    width: 100%; 
    button {
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .storeLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin-top: 16px;

    img {
      width: 100%;
    }
    
    a {
      padding-right: 8px;
    }
  }

}