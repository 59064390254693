
.Logo {
  --dome-color: #21295c;
  --base-color: #21295c;
  --needle-color: #15d3a9;
  --text-color: #21295c;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 24px;

  .mark {
    margin-right: 5%;
    height: 100%;
  }
  .text {
    margin-top: 2%;
    height: 85%;
  }

  &[large] {
    height: 36px;
  }

  &[stacked] {
    flex-direction: column;
    height: 60px;

    .mark {
      margin-right: 0px;
      margin-bottom: 3%;
    }
    .text {
      height: 70%;
      width: 100%;
    }

  }
}
