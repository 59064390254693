@import '../../style/_theme.scss';
@import '../../style/_text-styles.scss';
@import '../../style/_colors.scss';

.AppModal {
  .content {
    top: 50%;
    transform: translateY(-50%);
    width: 320px;
    z-index: calc(var(--z-index-app-modal) + 1);
  }

  .backdrop {
    z-index: var(--z-index-app-modal);
  }

  .header {
    align-items: center;
    margin-bottom: 16px;

    .headerClose {
      &:hover {
        cursor: pointer;
      }
    }
    
    .modalIcon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      background: var(--secondary-blue-75);
      border-radius: 50%;
      
      svg {
        width: 20px;
        height: 20px;
        fill: white;
      }
    }

    .modalIconTeal {
      background: var(--primary-teal-100);
    }

    .title {
      @include text-small-semibold;
      @media (min-width: $break-tiny-mobile) {
        @include text-normal-semibold;
      }
      @media (min-width: $break-tablet) {
        @include text-large-semibold;
      }
      flex: 1;
    }
  }

  .body {
    @include text-small;
    span[semibold] {
      @include text-small-semibold;
    }

    @media (min-width: $break-tiny-mobile) {
      @include text-normal;
      span[semibold] {
        @include text-normal-semibold;
      }
    }

    color: var(--secondary-black-50);

    margin-bottom: 16px;
  }

  form {
    margin: 0;
    input {
      width: 100%;
      margin: 0;
    }
  }
}

.ShareLoadingModal {
  .loadingSpinner {
    margin-top: 0;
  }

  .loadingCaption {
    text-align: center;
    color: var(--secondary-black-50);
    margin-top: 4px;
    padding-bottom: 8px;
  }
}