
:root {
  --primary-blue-100: #21295C;
  --primary-blue-75: #595E85;
  --primary-blue-50: #9094AD;
  --primary-blue-25: #C8C9D6;
  --primary-blue-10: #E9EAEF;

  --primary-teal-100: #15D3A9;
  --primary-teal-75: #50DEBE;
  --primary-teal-50: #8AE9D4;
  --primary-teal-25: #C5F4EA;
  --primary-teal-10: #E8FBF6;

  --primary-green-100: #0d9d76;
  --primary-green-75: #67b39a;
  --primary-green-50: #96ccbb;
  --primary-green-25: #c9e5dd;
  --primary-green-10: #e9f5f1;

  --secondary-blue-100: #266DD3;
  --secondary-blue-75: #5C92DE;
  --secondary-blue-50: #93B6E9;
  --secondary-blue-25: #C9DBF4;
  --secondary-blue-10: #E9F0FB;

  --secondary-ochre-100: #FCAA67;
  --secondary-ochre-75: #FDBF8D;
  --secondary-ochre-50: #FDD4B3;
  --secondary-ochre-25: #FEEAD9;
  --secondary-ochre-10: #FFF7F0;

  --secondary-red-100: #D33F49;
  --secondary-red-75: #DE6F77;
  --secondary-red-50: #E99FA4;
  --secondary-red-25: #F4CFD2;
  --secondary-red-10: #FBECED;

  --secondary-black-100: #000000;
  --secondary-black-75: #404040;
  --secondary-black-50: #808080;
  --secondary-black-25: #BFBFBF;
  --secondary-black-10: #E6E6E6;

  --chart-tidal: #BA529F;
  --panel: #fafafa;
}

