@import '../style/_theme.scss';

.BusyBackdrop {
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    margin: auto;
  }
}