
$savvy-orange: rgb(242, 117, 96); // #f27560
$savvy-dark-grey-blue: rgb(51, 57, 84); // #333954
$savvy-dark-sky-blue: rgb(74, 144, 226); // #4a90e2
$savvy-ocean-blue: rgb(0, 118, 166); // #0076a6
$savvy-barney-purple: rgb(163, 0, 166); // #a300a6
$savvy-cool-grey: rgb(164, 170, 179); // #a4aab3
$savvy-cool-grey-two: rgb(176, 190, 197); //#b0bec5
$savvy-heavy-grey: #828282;
$savvy-light-grey: #DADCE0;
$savvy-light-background: #fafafa;

$savvy-green: #11a67d;
$savvy-red: #a6170d;
$savvy-blue: #4a90e2;


$savvy-light-green: #6FCF97;


/* 0.8 opacity */
$savvy-blended-orange: rgba(242, 117, 96, .5);
$savvy-blended-dark-grey-blue: rgba(51, 57, 84, .8);
$savvy-blended-sky-blue: rgba(74, 144, 226, .8);
$savvy-blended-ocean-blue: rgba(0, 118, 166, .8);
$savvy-blended-barney-purple: rgba(163, 0, 166, .8);
$savvy-blended-cool-grey: rgba(164, 170, 179, .8);
$savvy-blended-cool-grey-two: rgba(176, 190, 197, .8);

/* 0.12 opacity */
$savvy-soft-ocean-blue: rgba(0, 118, 166, .12);

/* font colours */
$savvy-dark-text: rgba(0,0,0, .87);

$savvy-dimmed-text: #BDBDBD;


/* Only use this lighter text in rare cases!
The contrast doesn't generally work well */
$savvy-lighter-text: rgba(0,0,0, .54);


/* Stick to these three sizes, avoiding the small whenever possible
since it's not overly great to read on smaller (320) devices */
$savvy-font-large: 2rem;
$savvy-font-semi-large: 1.8rem;
$savvy-font-normal: 1.6rem;
$savvy-font-small: 1.4rem;

// Only use this extra small in extreme rare cases. We should always aim
// to just use the large/normal/small for consistency. But if it *really*
// doesn't fit on iPhone 5, you can use this one...
$savvy-font-extra-small: 1.2rem;
$savvy-font-extra-large: 2.4rem;

$savvy-weight-bold: 500;
$savvy-weight-normal: 400;

/* media query breakpoints */
$break-tiny-mobile: 320px; // smartphones, iPhone, portrait 480x320 phones
$break-mobile: 480px; // portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide.
$break-tablet: 640px; // portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones
$break-landscape-tablet: 960px; // tablet, landscape iPad, lo-res laptops ands desktops
$break-desktop: 1025px; // big landscape tablets, laptops, and desktops