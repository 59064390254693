@import '../style/_theme.scss';

.AppToast {
  position: fixed;
  z-index: var(--z-index-app-toast);
  pointer-events: none;

  width: 300px;
  
  left: 0;
  right: 0;
  margin: auto;

  --bottom-start: 0;
  --bottom-offset: 30px;
  --top-start: auto;
  --top-offset: auto;

  bottom: var(--bottom-offset);
  top: var(--top-offset);

  animation: fadein 0.2s;
  
  &.hide {
    visibility: hidden;
    animation: fadeout 0.15s;
  }
  
  @keyframes fadein {
    from {
      visibility: hidden;
      top: var(--top-start);
      bottom: var(--bottom-start);
      opacity: 0;
    }
    to {
      visibility: visible;
      top: var(--top-offset);
      bottom: var(--bottom-offset);
      opacity: 0.8;
    }
  }
  
  @keyframes fadeout {
    from {
      visibility: visible;
      top: var(--top-offset);
      bottom: var(--bottom-offset);
      opacity: 0.8;
    }
    to {
      visibility: hidden;
      top: var(--top-start);
      bottom: var(--bottom-start);
      opacity: 0;
    }
  }
}

@media (max-width: $break-tablet) {
  .AppToast {
    --bottom-start: auto;
    --bottom-offset: auto;
    --top-start: 0;
    --top-offset: 70px;
  }

  :global(.route-scene) .Toast,
  :global(.calculating-scene) .Toast {
    --top-offset: 130px;
  }
}

