
@import '../style/_theme.scss';

.Marker {
  --growth-factor: 1;
  z-index: var(--z-index-chart-markers);
  svg {
    transition: transform 0.2s ease-out;
    transform-origin: center 35px;
    transform: scale(var(--growth-factor));
  }

  &.failing {
  }

  &.draggable {
    cursor: move;
  }

  &.created {
    svg {
      animation: growBounce 0.8s;
    }
  }


  &.focussed {
    --growth-factor: 1.45;
    svg {
      animation: bounce 0.8s;
    }
  }

  &.minimized {
    border-radius: 50%;
    background: var(--secondary-blue-100);
    width: 10px;
    height: 10px;
    border: 1px solid var(--secondary-black-10);
    svg {
      display: none;
    }
  }

    
  @keyframes bounce {
    0% {
      transform: translateY(0px) scale(1);
    }

    20% {
      transform: translateY(-12px) scale(calc(var(--growth-factor) + 0.2)) scaleY(1.05);
    }


    40% {
      transform: translateY(0px) scale(calc(var(--growth-factor) + 0)) scaleY(1);
    }

    60% {
      transform: translateY(0px) scale(calc(var(--growth-factor) + 0.02)) scaleY(1.05);
    }

    80% {
      transform: translateY(0px) scale(calc(var(--growth-factor) + 0)) scaleY(1);
    }

    100% {
      transform: translateY(0px) scale(var(--growth-factor));
    }
  }

  @keyframes growBounce {
    0% {
      transform: translateY(0px) scale(0.1);
    }

    20% {
      transform: translateY(-12px) scale(calc(var(--growth-factor) + 0.2)) scaleY(1.05);
    }


    40% {
      transform: translateY(0px) scale(calc(var(--growth-factor) + 0)) scaleY(1);
    }

    60% {
      transform: translateY(0px) scale(calc(var(--growth-factor) + 0.02)) scaleY(1.05);
    }

    80% {
      transform: translateY(0px) scale(calc(var(--growth-factor) + 0)) scaleY(1);
    }

    100% {
      transform: translateY(0px) scale(var(--growth-factor));
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
}