@import '../../style/_text-styles.scss';

.TrialExplainer {

  color: var(--primary-blue-100);

  .title {
    margin-bottom: 24px;
  }

  .content {
    max-height: calc(100vh - 270px);
    overflow-y: auto;
  }
}