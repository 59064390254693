@import '../../style/_theme.scss';

.AnchorAlarmControl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .cta {
    margin: 0;
    margin-left: 8px;
  }

  .close {
    width: 40px;
    padding: 0;
  }

  .disarm {
    background-color: var(--secondary-red-100);
  }

  .adjust {
    width: 40px;
    padding: 0;
    & svg {
      fill: var(--secondary-blue-100);
    }
  }
}

.AnchorAlarmCurrentLocationMarker {
  z-index: var(--z-index-custom-user-location-marker);
}