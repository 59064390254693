@import '../style/_theme.scss';
@import '../style/_text-styles.scss';
@import '../style/_elevation.scss';

.Toast {
  pointer-events: none;
  min-width: 300px;
  background-color: var(--primary-blue-75);
  opacity: 0.8;
  text-align: center;
  border-radius: 4px;
  padding: 8px 16px;

  @include shadow-1dp;

  .text {
    @include text-normal;
    color: white;
  }

  .cta {
    pointer-events: auto;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: var(--primary-teal-50)
  }
}