
@mixin text-extra-large {
  @include __font-family;
  @include __font-size-extra-large;
  @include __font-weight-normal;
}

@mixin text-extra-large-semibold {
  @include __font_family;
  @include __font-size-extra-large;
  @include __font-weight-semibold;
}

@mixin text-large {
  @include __font_family;
  @include __font-size-large;
  @include __font-weight-normal;
}

@mixin text-large-semibold {
  @include __font_family;
  @include __font-size-large;
  @include __font-weight-semibold;
}

@mixin text-normal {
  @include __font_family;
  @include __font-size-normal;
  @include __font-weight-normal;
}

@mixin text-normal-semibold {
  @include __font_family;
  @include __font-size-normal;
  @include __font-weight-semibold;
}

@mixin text-small {
  @include __font_family;
  @include __font-size-small;
  @include __font-weight-normal;
}

@mixin text-small-semibold {
  @include __font_family;
  @include __font-size-small;
  @include __font-weight-semibold;
}

@mixin text-small-bold {
  @include __font_family;
  @include __font-size-small;
  @include __font-weight-bold;
}


@mixin text-extra-small {
  @include __font_family;
  @include __font-size-extra-small;
  @include __font-weight-normal;
}

@mixin text-extra-small-semibold {
  @include __font_family;
  @include __font-size-extra-small;
  @include __font-weight-semibold;
}

@mixin text-caption-small-semibold {
  @include __font_family;
  @include __font-size-small;
  @include __font-weight-semibold;
  @include __uppercase;
}

@mixin text-caption-extra-small-semibold {
  @include __font_family;
  @include __font-size-extra-small;
  @include __font-weight-semibold;
  @include __uppercase;
}

@mixin text-caption-extra-small-bold {
  @include __font_family;
  @include __font-size-extra-small;
  @include __font-weight-bold;
  @include __uppercase;
}


///////////////////////////////////////////////
// Private - used in styles above
@mixin __base {
  text-transform: unset;
  letter-spacing: normal;
}
@mixin __font-family {
  @include __base;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
@mixin __font-size-extra-large {
  font-size: 2.4rem;
  line-height: 1.55;
}
@mixin __font-size-large {
  font-size: 2.0rem;
  line-height: 1.4;
}
@mixin __font-size-normal {
  font-size: 1.6rem;
  line-height: 1.5;
}
@mixin __font-size-small {
  font-size: 1.4rem;
  line-height: 1.428;
}
@mixin __font-size-extra-small {
  font-size: 1.2rem;
  line-height: 1.33;
}
@mixin __font-size-extra-large {
  font-size: 2.4rem;
  line-height: 1.55;
}
@mixin __font-weight-bold {
  font-weight: 600;
}
@mixin __font-weight-semibold {
  font-weight: 500;
}
@mixin __font-weight-normal {
  font-weight: 400;
}
@mixin __uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}