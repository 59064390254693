@import '../../style/_theme.scss';

.ChartFab {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  width: 40px;
  height: 40px;

  @media (min-width: $break-desktop) {

    width: 30px;
    height: 30px;
  }

  transition: top .2s ease-in-out;

  .iconContainer {
    position: relative;
    padding: 8px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);

    @media (min-width: $break-desktop) {
      padding: 0;
    }



    @media (hover: hover) {
      &:hover {
        background-color: #f2f2f2;
      }
    }


    &:after {
      top: 6px;
      right: 3px;
    }
  }

  .fabIcon {
    position: relative;
    fill: var(--primary-blue-100);

    @media (min-width: $break-desktop) {
      padding: 3px 5px;
      width: 20px;
    }
  }

  &.active .fabIcon {
    fill: #3cb6e3;
  }

  &.disabled {

    .disabledLine {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 20px;
      background-color: rgb(255,191,191);

      transform: translateX(-50%) rotate(-45deg);
    }
    .fabIcon {
      opacity: 0.25;
    }
  }
}
