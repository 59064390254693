@import '../style/_theme.scss';

.WarningPage {
  display: flex;
  flex-direction: column;
  font-size: $savvy-font-normal;
  font-weight: $savvy-weight-normal;
  color: $savvy-dark-grey-blue;
  line-height: 1.3;

  // Create 'card like' visual on desktop
  max-width: 400px;
  height: 100vh;
  margin: 0 auto;
  padding: 16px;

  .logoContainer {
    background: white;
    color: $savvy-dark-grey-blue;
    padding: 0px 6px 0px 6px;
  }
  .logoContainer p {
    font-size: $savvy-font-normal;
    margin: 0 6px 6px 6px;
  }
  .logo {
    display: block;
    width: 200px;
    height: 101px;
    margin: 0 auto;
    padding: 20px 0px 0px 0px;
  }

  h1 {
    margin-bottom: 24px;
    font-size: $savvy-font-large;
    width: 100%;
  }
}