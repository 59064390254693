
:global(.fade-enter) {
  opacity: 0.01;
}

:global(.fade-enter-active) {
  opacity: 1;
  transition: opacity .3s ease-in;
}

:global(.fade-exit) {
  opacity: 1;
}

:global(.fade-exit-active) {
  opacity: 0.01;
  transition: opacity .3s ease-in;
}



:global(.bounce-up-enter) {
  position: absolute;
  transform: translateY(1000px);
  opacity: 0.01;
}

:global(.bounce-up-enter-active) {
  position: absolute;
  transform: translateY(0px);
  opacity: 1;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(0.6, -0.05, 0.735, 0.045);
}

:global(.bounce-up-exit) {
  position: absolute;
  opacity: 1;
  transform: translateY(0px);
}

:global(.bounce-up-exit-active) {
  position: absolute;
  transform: translateY(-1000px);
  opacity: 0.01;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(0.6, -0.05, 0.735, 0.045);
}


:global(.bounce-left-enter) {
  position: absolute;
  transform: translateX(1000px);
  opacity: 0.01;
}

:global(.bounce-left-enter-active) {
  position: absolute;
  transform: translateX(0px);
  opacity: 1;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(0.6, -0.05, 0.735, 0.045);
}

:global(.bounce-left-exit) {
  position: absolute;
  opacity: 1;
  transform: translateX(0px);
}

:global(.bounce-left-exit-active) {
  position: absolute;
  transform: translateX(-1000px);
  opacity: 0.01;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(0.6, -0.05, 0.735, 0.045);
}



:global(.slide-down-enter) {
  position: absolute;
  transform: translateY(-80px);
  opacity: 0.01;
}

:global(.slide-down-enter-active) {
  position: absolute;
  transform: translateY(0px);
  opacity: 1;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(.73,1.12,.75,1.05)
}

:global(.slide-down-exit) {
  position: absolute;
  opacity: 1;
  transform: translateY(0px);
}

:global(.slide-down-exit-active) {
  position: absolute;
  transform: translateY(-80px);
  opacity: 0.01;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(.73,1.12,.75,1.05)
}




:global(.slide-up-enter) {
  position: absolute;
  transform: translateY(80px);
  opacity: 0.01;
}

:global(.slide-up-enter-active) {
  position: absolute;
  transform: translateY(0px);
  opacity: 1;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(.73,1.12,.75,1.05)
}

:global(.slide-up-exit) {
  position: absolute;
  opacity: 1;
  transform: translateY(0px);
}

:global(.slide-up-exit-active) {
  position: absolute;
  transform: translateY(80px);
  opacity: 0.01;
  transition: opacity .3s ease-in, transform .3s cubic-bezier(.73,1.12,.75,1.05)
}

