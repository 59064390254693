.AnchorAlarmModal {
  .title {
    text-align: center;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cta {
      min-width: 80px;
    }
  }
}