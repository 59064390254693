@import '../../style/_text-styles.scss';

.UpgradeModalContent {
  span[semibold] {
    @include text-normal-semibold;
  }

  p {
    margin-bottom: 1rem;
  }

  .learnMoreContainer {
    display: flex;
    flex-direction: column;
  }

  .video {
    width: 100%;
    margin-bottom: 8px;
    border-radius: 8px;
  }

  .featuresList {
    list-style: none;
    margin: 0;

    .feature {
      margin: 0;
      margin-left: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;

      &::before {
        content: " ";
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 100px;
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
}