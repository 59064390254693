
@import '../style/_theme.scss';

.TidalDiamondMarker {
  z-index: calc(var(--z-index-chart-markers) - 1);
  pointer-events: none;
  --growth-factor: 1.4;
  svg {
    transform: scale(var(--growth-factor));
    animation: growBounce 0.8s;
  }
  .tidalDiamond {
    svg {
      fill: var(--chart-tidal);
    }
  }
}

@keyframes growBounce {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(calc(var(--growth-factor) + 0.25));
  }

  40% {
    transform: scale(calc(var(--growth-factor) + 0));
  }

  60% {
    transform: scale(calc(var(--growth-factor) + 0.05));
  }

  80% {
    transform: scale(calc(var(--growth-factor) + 0));
  }

  100% {
    transform: scale(calc(var(--growth-factor) + 0));
  }
}