@import '../style/_theme.scss';
@import '../style/_text-styles.scss';

.GeolocationControl {
  .geolocationButton {
  }
}

.geolocationDot {
  &.showHeading {
    background-color: transparent;
    background-image: url(../assets/img/geolocation.png);
    background-size: cover;
    border: none;
    border-radius: 0;
    width: 64px;
    height: 64px;
    
    &> div {
      display: none;
    }

    &::before {
      // disable pulse when showing heading
      display: none;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      border-radius: 0;
      width: 10px;
      height: 200px;
      left: 31.5px;
      top: -192px;
      border-style: solid;
      border-width: 1px;
      border-right-width: 0px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      border-color: #21295C;
      box-shadow: none;
    }
  }
}

.accuracyCircle {
  &.showHeading {
    // Keep the default blue colour for accuracy circle for now
  }
}

@-webkit-keyframes savvy-user-location-dot-pulse {
  0%   { -webkit-transform: scale(1); opacity: 0.6; }
  70%  { -webkit-transform: scale(2); opacity: 0; }
  100% { -webkit-transform: scale(1); opacity: 0; }
}
@-ms-keyframes savvy-user-location-dot-pulse {
  0%   { -ms-transform: scale(1); opacity: 0.6; }
  70%  { -ms-transform: scale(2); opacity: 0; }
  100% { -ms-transform: scale(1); opacity: 0; }
}
@keyframes savvy-user-location-dot-pulse {
  0%   { transform: scale(1); opacity: 0.6; }
  70%  { transform: scale(2); opacity: 0; }
  100% { transform: scale(1); opacity: 0; }
}

/* TODO(jliebrand): add our own (non mint blue!) gps icons! */
:global(.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate) {}
:global(.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-waiting) {}
:global(.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active) {}