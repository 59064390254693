@import '../style/_theme.scss';

.SvgButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.withIcon {
    padding: 6px 12px 6px 8px;
  }

  &.rounded {
    /* trick to get "pill" like borders */
    border-radius: 500px;
  }

  &.clear {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  &.stacked {
    flex-direction: column;
  }

  .icon {

    svg {
      height: 24px;
      width: 24px;
    }
  }
}
