@import '../style/_theme.scss';

.Modal {
  visibility: hidden;
}

.Modal .modalBackdrop {
  transition: all 0.2s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);

  background: $savvy-dark-grey-blue;
  opacity: 0.4;
}

.Modal .modalContent {
  position: fixed;
  left: 0;
  right: 0;
  z-index: calc(var(--z-index-modal) + 1);

  top: 30px;
  width: 400px;
  line-height: 1.3;
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  transition: all 0.2s;
  background: white;
  opacity: 0;
  padding: 16px 16px 12px 16px;
  border-radius: 8px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.Modal.show {
  visibility: visible;
}
.Modal.show .modalContent {
  opacity: 1;
}

.Modal.fullBleed .modalContent {
  padding: 0;
}

@media (min-width: $break-tablet) {
  .Modal .modalContent {
    max-height: 80vh;
  }
}

@media (max-width: $break-tablet) {
  .Modal.mobileFullScreen .modalContent {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: inherit;
    max-height: inherit;
    width: inherit;
    margin: 0;
    border-radius: 0;
  }
}