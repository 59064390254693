
:root {
  --z-index-chart: 100;
  --z-index-custom-user-location-marker: 101;
  --z-index-chart-markers: 110;
  --z-index-chart-fabs: 120;
  --z-index-chart-scene: 130;
  --z-index-scene: 1000;
  --z-index-drawer: 1100;
  --z-index-modal: 1200;
  --z-index-app-modal: 1300;
  --z-index-error-modal: 1400;
  --z-index-app-toast: 1500;
}

