@import '../style/_theme.scss';

.CopiableField {
  align-items: center;
  height: 36px;
  border: 1px solid var(--secondary-black-25);
  border-radius: 4px;
  
  .value {
    flex: 1;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copyButton {
    width: 24px;
    height: 24px;
    box-sizing: content-box;
    margin: 0;
    padding: 0 8px;
    svg {
      fill: var(--primary-blue-75);
      width: 24px;
      height: 24px;
    }
  }
}