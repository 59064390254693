@import '../../style/_theme.scss';
@import '../../style/_text-styles.scss';

.SharedOpenLandingScene {
  padding: 8px;

  .omnibox {
    position: relative;
    z-index: 1;
    max-width: var($break-landscape-tablet);
    @media (min-width: $break-landscape-tablet) {
      max-width: 420px;
    }
    width: 100%;

    background-color: var(--panel);
    min-height: 48px;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    align-items: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .footerDesktop {
    @media (max-width: $break-landscape-tablet) {
      display: none;
    }
    background-color: var(--panel);
    position: relative;
    padding: 16px;
    width: calc(420px - 32px);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0 0 2px 2px;

    .caption {
      @include text-normal-semibold;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .cta {
      width: 100%;
    }
  }

  .footerMobile {
    @media (min-width: $break-landscape-tablet) {
      display: none;
    }
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 16px 16px 16px;
    background-color: var(--panel);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 16px 16px 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    .caption {
      @include text-normal-semibold;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .cta {
      width: 100%;
    }
  }

  :global(.mapboxgl-ctrl-geolocate) {
    display: none;
  }
}