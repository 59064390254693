@import '../style/_theme.scss';
@import '../style/_text-styles.scss';

.NavigationControl {

  .compass {
    transition: all 0s ease 0s, opacity .3s ease-in-out 1s;
    opacity: 0;
    pointer-events: none;

    @media (max-width: $break-tablet) {
      position: absolute;
      top: calc(60px + var(--fab-container-height, 0));
    }
    
    &.rotated {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer;
    }

    &.toggleable {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer;
    }
  }

  .zoomOutButton,
  .zoomInButton {
    margin: 0 0 10px 0 !important;
    @media (max-width: $break-tablet) {
      display: none !important;
    }
  }

}