%flexbox,
:global([flexbox]),
:global(.flexbox) {
  display: flex;
}

%horizontal,
:global([horizontal]),
:global(.horizontal) {
  flex-direction: row;
}

%vertical,
:global([vertical]),
:global(.vertical) {
  flex-direction: column;
}

%center,
:global([center]),
:global(.center) {
  justify-content: center;
}

%flex,
:global([flex]),
:global(.flex) {
  flex: 1 1 auto;
}

%align-items-center,
:global([align-items-center]),
:global(.align-items-center) {
  align-items: center;
}

%align-items-start,
:global([align-items-start]),
:global(.align-items-start) {
  align-items: start;
}

%align-items-end,
:global([align-items-end]),
:global(.align-items-end) {
  align-items: end;
}

%justify-content-start,
:global([justify-content-start]),
:global(.justify-content-start) {
  justify-content: flex-start;
}

%justify-content-center,
:global([justify-content-center]),
:global(.justify-content-center) {
  justify-content: center;
}

%justify-content-end,
:global([justify-content-end]),
:global(.justify-content-end) {
  justify-content: flex-end;
}

%justify-content-space-around,
:global([justify-content-space-around]),
:global(.justify-content-space-around) {
  justify-content: space-around;
}

%justify-content-space-between,
:global([justify-content-space-between]),
:global(.justify-content-space-between) {
  justify-content: space-between;
}

%justify-content-center,
:global([justify-content-center]),
:global(.justify-content-center) {
  justify-content: center;
}

%flex-wrap,
:global([flex-wrap]),
:global(.flex-wrap) {
  flex-wrap: wrap;
}


