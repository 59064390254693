@import '../style/_theme.scss';
@import '../style/text-styles.scss';

.ErrorCatcher {
  .content {
    top: 20%;
    width: 320px;
    z-index: calc(var(--z-index-error-modal) + 1);
  }

  .backdrop {
    z-index: var(--z-index-error-modal);
  }

  .header {
    align-items: center;
    margin-bottom: 16px;

    .iconContainer {
      margin-right: 12px;
    }

    .icon {
      width: 32px;
      height: 32px;
      background: var(--secondary-red-100);
      border-radius: 50%;

      svg {
        width: 24px;
        height: 24px;
        fill: white;
      }
    }

    .title {
      @include text-small-semibold;
      @media (min-width: $break-tiny-mobile) {
        @include text-normal-semibold;
      }
      @media (min-width: $break-tablet) {
        @include text-large-semibold;
      }
    }
  }

  .body {
    @include text-small;
    span[semibold] {
      @include text-small-semibold;
    }

    @media (min-width: $break-tiny-mobile) {
      @include text-normal;
      span[semibold] {
        @include text-normal-semibold;
      }
    }

    span[black] {
      color: var(--secondary-black-50);
    }

    color: var(--secondary-black-50);

    margin-bottom: 24px;
  }

  .buttonContainer {
    margin-top: 16px;
  }
}