@import '../style/_text-styles.scss';

.BulletList {

  .bullet {
    margin-bottom: 8px;
    align-items: stretch;

    .dot {
      width: 24px;
      height: 24px;
      margin: 4px 12px 0px 12px;
      border-radius: 100%;
      background-color: var(--secondary-black-10);
    }
    .line {
      width: 4px;
      margin: -4px 22px;
      background-color: var(--secondary-black-10);
    }
    .item {
      margin: 0px 16px 16px 16px;

      .itemTitle {
        @include text-large-semibold;
      }
      .desc {

      }
    }

    &:last-child {

      .dot {
        background-color: white;
        border-radius: 0;
        width: 8px;
        height: 8px;
        margin: 0 15px;

        border: solid var(--secondary-black-10);
        border-width: 0 5px 5px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);

      }

      .line {
        display: none;
      }
      margin-bottom: 24px;
    }
  }
}